import styled, { createGlobalStyle } from 'styled-components'
import Typography from '@ntuctech/devex-tangram/Typography'
import Toast from '@ntuctech/devex-tangram/Toast'
import Grid from '@ntuctech/devex-tangram/Grid'
import Box from '@ntuctech/devex-tangram/Box'
import ButtonOutlined from '@ntuctech/devex-tangram/Button/ButtonOutlined'
import Image from '../../Image/Image'
import Tabs, { Tab, TabPanel, TabList } from '@ntuctech/devex-tangram/Tabs'
import TabContentTitle from '../../AccountsCommon/TabContentTitle'
import TabContent from '../../AccountsCommon/TabContent'
import Link from '@ntuctech/devex-tangram/Link'
import SvgArrowRight from '@ntuctech/devex-tangram/Icon/SvgArrowRight'
import SvgCheck from '@ntuctech/devex-tangram/Icon/SvgCheck'
import SvgArrowDown from '@ntuctech/devex-tangram/Icon/SvgArrowDown'
import SvgArrowUp from '@ntuctech/devex-tangram/Icon/SvgArrowUp'
import ErrorIcon from '../assets/voucher-error-icon.svg'
import { SCREEN_SIZE } from '../../../lib/Media'
import { ContentSection, PaddingBox, Skeleton } from '../../../lib/commonStyles'
import { Skeleton as SkeletonStructure } from '@ntuctech/devex-tangram'

const SC = {}
SC.GlobalStyle = createGlobalStyle`
  body {
    overflow: ${props => (props.openSideSheet ? 'hidden !important' : 'auto')};
  }
  #body-container {
    padding: 0 0 2.5rem 0;
  }
`
SC.DetailsTab = styled(Typography)`
  margin: ${props => (props.isSideSheet ? '1rem 0' : '1rem 1rem 0')};
  &[data-seeall='true'] {
    overflow: hidden;
    position: relative;
    height: 7.6em; /* exactly 5 lines */

    &:after {
      content: '';
      text-align: left;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4.6em;
      background: linear-gradient(
        7.67deg,
        #ffffff 16.8%,
        rgba(255, 255, 255, 0) 97.43%
      );
    }
  }
`

SC.OGVoucherContainer = styled.div`
  margin-right: -36px;
  width: 100%;
  .voucher-container {
    padding: 0;
    margin: 0;
    display: block;
  }
  .text-info {
    position: relative;
    width: 100%;
    flex-direction: row;
    margin-left: 0.5rem;
    padding: 0.375rem 0;
  }
  .qrcode-box {
    text-align: center;
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: auto;
  }
  .grid-container {
    padding: 0 0.25rem;
  }
  .styled-grid {
    padding: 0;
  }
  .top-icon {
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: -0.625rem;
    left: 3.25rem;
    border-radius: 50%;
    background-color: #f6f6f6;
    box-shadow: 0 1px rgb(0 0 0 / 9%);
  }

  .bottom-icon {
    width: 1rem;
    height: 1rem;
    bottom: -0.625rem;
    left: 3.25rem;
    border-radius: 50%;
    position: absolute;
    background-color: #f6f6f6;
    box-shadow: 0 1px rgb(0 0 0 / 9%);
    transform: rotate(180deg);
  }
`

SC.PDPVoucherSwimlane = styled.div`
  width: ${props => (props.isThreeColumnLayout ? '97%' : '45%')};
  height: 12.188rem;
  background-color: #f9f4fb;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1.5rem;

  ${SCREEN_SIZE.Below.Tablet} {
    width: 100%;
    margin-top: 0.125rem;
  }

  .top-icon {
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: -0.625rem;
    left: 3.25rem;
    border-radius: 50%;
    background-color: #f9f4fb;
    box-shadow: 0 1px rgb(0 0 0 / 9%);
  }

  .bottom-icon {
    width: 1rem;
    height: 1rem;
    bottom: -0.625rem;
    left: 3.25rem;
    border-radius: 50%;
    position: absolute;
    background-color: #f9f4fb;
    box-shadow: 0 1px rgb(0 0 0 / 9%);
    transform: rotate(180deg);
  }

  .ReactVirtualized__Grid__innerScrollContainer,
  .ReactVirtualized__Grid__innerScrollContainer > div {
    width: ${props => (props.vd === 1 ? '100% !important' : '15.75rem')};
    max-width: ${props => (props.vd === 1 ? '100% !important' : '15.75rem')};
  }

  #card-container {
    padding-left: 0 !important;
    padding-right: ${props =>
      props.vd === 1 ? '0 !important' : '0.75rem !important'};
  }
`

SC.VoucherWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

SC.NewSkeleton = styled(SkeletonStructure)`
  height: ${props => props.height || '1'}rem;
  width: ${props => `${props.width}rem !important` || '100%'};
  margin-bottom: ${props => props.mb || '0'}rem;
  margin-left: ${props => props.ml || '0'}rem;
  border-radius: ${props => props.br || '0'}px;
  max-width: 100%;
  border-radius: 0.25rem;
`

SC.ContainerWrapper = styled(Grid)`
  cursor: pointer;
  padding: 0 0.25rem !important;
  margin-bottom: 0.75rem;
  ${props => props.isVoucher && 'position: absolute'};
  ${SCREEN_SIZE.From.Desktop} {
    ${props => props.isNewVoucherEnable && !props.isVoucher && 'width: 50%'};
  }
  ${SCREEN_SIZE.Below.Desktop} {
    ${props => props.isNewVoucherEnable && !props.isVoucher && 'width: 50%'};
  }
  ${SCREEN_SIZE.Below.Tablet} {
    ${props => props.isNewVoucherEnable && !props.isVoucher && 'width: 50%'};
  }
  ${SCREEN_SIZE.Below.Mobile} {
    ${props => props.isNewVoucherEnable && !props.isVoucher && 'width: 100%'};
  }
`

SC.StyledTabBox = styled(Box)`
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
`
SC.TermsContainer = styled(Box)`
  margin-top: 1rem;
  margin-top: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  a {
    color: #1557bf;
  }
`

SC.StyledSection = styled(Box)`
  font-family: Lato, sans-serif;
  padding: 1rem 2.5rem;
  background-color: #ffffff;
  .heading {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #333333;
  }
  .description {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #333333;
  }
  .highlight {
    color: #ea6100;
    font-size: 0.875rem;
  }
  h6 {
    color: #333333;
    font-size: 1.125rem;
    padding-bottom: 1rem;
  }
`
SC.TransactionItem = styled.div`
  display: flex;
  border-bottom: 1px solid #eaeaea;
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem 0;
  line-height: 1.5rem;

  &[data-see-all='true'] {
    overflow: hidden;
    position: relative;
    &:after {
      content: '';
      text-align: left;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4.6em;
      background: linear-gradient(
        7.67deg,
        #ffffff 16.8%,
        rgba(255, 255, 255, 0) 97.43%
      );
    }
  }
  &[data-last-item='true'] {
    border: none;
  }
  .date {
    font-size: 0.75rem;
    color: #999999;
    line-height: 1rem;
    font-weight: 400;
  }
  .amount {
    font-weight: 700;
    line-height: 1rem;
    width: 6rem;
    text-align: right;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    line-height: 1.5rem;
    color: #4d4d4d;
  }
`
SC.RewardsContainer = styled.div`
  ${SCREEN_SIZE.Below.MobileL} {
    padding: 0 1rem 1rem;
  }
`
SC.QrCodeBox = styled(Box)`
  text-align: center;
`
SC.Balance = styled(Typography)`
  padding: 0.125rem 0 1.5rem;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 1.1;
  color: #333333;
  span {
    font-size: 1.25rem;
    line-height: 1.4;
    vertical-align: super;
  }
`
SC.BoxWrapper = styled(Box)`
  padding: 0.375rem 0;
`
SC.StyledQrCodeWrapper = styled(Box)`
  margin: 1rem 3rem 3.8125rem;
  padding: 2rem 2rem 1.5rem;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 0.5rem;
  position: relative;
  img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
  }
  canvas {
    position: relative;
    z-index: 20;
  }
`

SC.VoucherDetailsBox = styled(Box)`
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /*To hide scrollbar in firefox */
  -webkit-overflow-scrolling: touch;
  ${SCREEN_SIZE.Only.Mobile} {
    height: calc(100vh - 3rem);
  } */
`

SC.StyledTabContent = styled(TabContent)`
  ${props =>
    props.isSideSheetEnable && 'padding: 0 !important; border: 0 !important;'}
  ${props => props.usenow && 'min-height:0;max-height:23.125rem;'}
  .text-info {
    position: relative;
    flex-direction: row;
    width: 100%;
    padding: ${props => (props.isNewVoucherEnable ? 0 : '0.375rem 0')};
  }
  .qrcode-box {
    text-align: center;
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
  .grid-container {
    padding: 0 0.25rem;
  }
  .styled-grid {
    padding: 0;
  }
  .styled-tab-list {
    padding-left: 0.5rem;
    margin-bottom: 1rem;
  }
  .styled-voucher {
    padding-left: 0;
  }
  .top-icon {
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: -0.625rem;
    left: 3.25rem;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 1px rgb(0 0 0 / 9%);
  }

  .bottom-icon {
    width: 1rem;
    height: 1rem;
    bottom: -0.625rem;
    left: 3.25rem;
    border-radius: 50%;
    position: absolute;
    background-color: white;
    box-shadow: 0 1px rgb(0 0 0 / 9%);
    transform: rotate(180deg);
  }
  .rewards button:not([role='tab']),
  .rewards button:not([role='tab']):focus {
    padding: 0.625rem 1rem;
    ${props =>
      !props.isNewVoucherEnable && 'height: 2.25rem; border: 1px solid #696969'}
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 0.25rem;
    color: white;
    font-size: 0.75rem;
    line-height: 1.3;
    min-width: 0;
    margin: 0.5rem 0.5rem 0.5rem 0;

    /* FPBlue / 500 */
    &._ndsTab--active,
    &._ndsTab--active:hover {
      background: #1557bf;
      border: 0;
      padding: 0.625rem 1rem;
      height: 2.25rem;
    }
    &._ndsTab--active ._ndsTab__label,
    &._ndsTab--active:hover {
      color: white;
      font-size: 0.75rem;
      line-height: 1rem;
      min-width: 0;
    }
    ._ndsTab__label {
      font-size: 0.75rem;
      line-height: 1rem;
      color: #696969;
    }
    ._ndsTabPanel {
      min-height: 23.4375rem;
    }
  }
`
SC.StyledTab = styled(Tab)`
  background: #1557bf;
  border-radius: ${props => (props.usenow ? '0.25rem' : '1.125rem')};
`
SC.StyledBox = styled('button')`
  display: flex;
  text-align: left;
  width: 100%;
  border: 1px solid #eaeaea;
  cursor: pointer;
  border-radius: 0.25rem;
  padding: 0.75rem 0;
  position: relative;
  align-items: center;
  &[data-new='false'] {
    align-items: center;
  }
  background-color: white;
  ${SCREEN_SIZE.Below.MobileL} {
    margin: auto;
  }
  ${SCREEN_SIZE.From.Tablet} {
    ${props =>
      props.isVoucher || props.isSideSheetEnable
        ? 'width: 100%;'
        : 'max-width: 20.5rem;'}
    ${props =>
      props.isVoucher &&
      props.isNewVoucherEnable &&
      !props.isAllfilterValue &&
      'height: 6.313rem !important'};
    ${props =>
      props.isVoucher &&
      !props.isNewVoucherEnable &&
      'height: 7.813rem !important'};
  }
  &[data-popup='false']:hover {
    border-color: #cccccc;
    .top-icon,
    .bottom-icon {
      box-shadow: 0 1px #cccccc;
    }
  }
  &[data-popup='false']:active {
    border-color: #2070ef;
    .top-icon,
    .bottom-icon {
      box-shadow: 0 1px rgb(32 112 239);
    }
  }
`
SC.NewBadge = styled.span`
  padding: 0.125rem 0.25rem;
  background: #e47412;
  border-radius: 0 0.125rem 0.125rem 0;
  font-size: 0.75rem;
  line-height: 1rem;
  position: absolute;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  font-family: Lato, sans-serif;
  left: 0;
  top: -1.5rem;
  margin-top: 0.25rem;
  align-self: baseline;
  ${props => props.isVoucher && 'position: absolute'}
`
SC.QrIconBox = styled(Box)`
  max-width: 3.75rem;
  text-align: center;
  padding: 0;
  margin-right: 0.5rem;
  margin-left: 0.75rem;
  .voucher-icon {
    opacity: 0;
    height: 0;
  }
`
SC.StyledTag = styled.span`
  padding: 0.25rem 0.5rem;
  background: ${props => (props.isNewVoucherEnable ? '#f2f2f2' : '#e8f0fd')};
  border-radius: 0.125rem;
  font-size: 0.75rem;
  font-family: Lato, sans-serif;
  text-align: center;
  color: ${props => (props.isNewVoucherEnable ? '#333' : '#1454b8')};
  display: inline-block;
  margin-right: 0.25rem;

  &[data-past='true'] {
    background: #f2f2f2;
    color: #666666;
  }
`
SC.DashedBox = styled(Box)`
  border-left: ${props =>
    props.isNewVoucherEnable ? 0 : '1px dashed #ebecee'};
  height: 100%;
`
SC.InfoBox = styled(Box)`
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: space-between;
`
SC.StyledLink = styled(Link)`
  ${props => !props.isNewVoucherEnable && 'display: flex'};
  float: ${props => (props.transactions ? 'left' : 'right')};
  margin-left: ${props => (props.transactions ? 0 : '1rem')};
  font-weight: bold;
  color: #1454b8;
  &[data-link-past='true'] {
    color: #4d4d4d;
  }
  &:hover,
  &:visited,
  &:focus {
    box-shadow: none;
    border: none;
  }
  svg {
    vertical-align: bottom;
  }
  span,
  .desktop {
    display: none;
  }

  ${SCREEN_SIZE.From.Tablet} {
    margin-left: 0;
    margin-right: 0.5rem;
    ${props =>
      props.isNewVoucherEnable
        ? 'min-width: 1.5rem; min-height: 1.5rem'
        : '3rem'};
    .mobile {
      display: none;
    }
    span,
    .desktop {
      display: inline;
      font-size: 0.75rem;
      line-height: 1rem;
      width: 1.5rem;
      height: 1.5rem;
    }
    .desktop,
    svg {
      ${props =>
        props.isNewVoucherEnable
          ? 'min-width: 1.5rem; min-height: 1.5rem; max-width: 1.5rem; max-height: 1.5rem;'
          : 'min-width: 1rem; min-height: 1rem; max-width: 1rem;max-height: 1rem;'}
    }
  }
`
SC.StyledSubHead1 = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.4;
  font-family: Lato, sans-serif;
  &[data-popup='true'] {
    word-break: break-word;
    width: 100%;
  }
  ${props => props.isTitle && 'font-weight: 700'};
  ${props => !props.view && 'padding: 0 0.5rem 0.5rem 0;'};
  &[data-past='true'] {
    color: #333333;
  }
  height: 1.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`
SC.StyledTitle = styled(Typography)`
  ${props => props.isEmpty && 'margin-top: 1.5rem !important'};
  ${props => props.isVoucher && 'margin-top: 0.25rem;'}
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: Lato, sans-serif;
  font-weight: 700;
  padding: 0;
  color: #333333;
  padding-right: 0.5rem;
  &[data-popup='true'] {
    word-break: break-word;
    width: 100%;
  }
  &[data-past='true'] {
    color: #333333;
  }
  ${props =>
    (props.isVoucher || props.voucherTab) &&
    'height: 1.4rem; overflow: hidden; text-overflow: ellipsis; white-space: initial; display: -webkit-box; -webkit-line-clamp: 1;-webkit-box-orient: vertical; word-break: break-all'}
`
SC.StyledSubTitle = styled(Typography)`
  margin-top: ${props =>
    props.isVoucher || props.isTitleAvailable ? '0' : '0.25rem'};
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #333333;
  font-family: Lato, sans-serif;
  &[data-popup='true'] {
    word-break: break-word;
    width: 100%;
  }
  font-weight: ${props => (props.isTitleAvailable ? '400' : '700')};
  ${props => !props.view && 'padding: 0 0.5rem 0.25rem 0;'};
  &[data-past='true'] {
    color: #333333;
  }
  ${props =>
    (props.isVoucher || props.voucherTab) &&
    'height: 1.4rem; overflow: hidden; text-overflow: ellipsis; white-space: initial; display: -webkit-box; -webkit-line-clamp: 1;-webkit-box-orient: vertical;word-break: break-all;'}
`
SC.StyledSubHead2 = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1.4rem;
  font-family: Lato, sans-serif;
  &[data-popup='true'] {
    word-break: break-word;
    width: 100%;
  }
  ${props => (props.isExpiresAt ? 'color: #666666' : 'color: #333333')}
`

SC.QrCode = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.4;
  color: #666666;
  padding: 1rem 0 0.25rem;
  position: relative;
  z-index: 20;
`
SC.Validity = styled(Typography)`
  color: ${props => (props.expired ? '#91082B' : '#666666')};
  position: relative;
  font-family: Lato, sans-serif;
  z-index: 20;
  font-size: 0.75rem;
  line-height: 1rem;
`
SC.StyledErrorBox = styled.div`
  padding-top: 1.5rem;
  text-align: center;
  width: 100%;
  h5 {
    padding: 0.5rem 0;
    color: #4d4d4d;
  }
  body {
    color: #666666;
  }
  &.error button {
    margin-top: 1rem;
  }
`
SC.StyledImageWrapper = styled.div`
  margin-top: 0.25rem;
  text-align: ${props => (props.center ? ' -webkit-center' : 'left')};
`
SC.ImageErrorLoader = styled.div`
  div {
    margin: 0.5rem auto auto;
  }
`
SC.Toast = styled(Toast)`
  margin-top: 6.25rem;
`
SC.HowToUseImage = styled(Image)`
  margin: 1rem 0;
`
SC.HowToUseDetails = styled(Typography)`
  margin: 0 0.5rem 0.5rem;
  li {
    margin-top: 0.5rem;
  }
`
SC.FilterBoxContainer = styled.div`
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`

SC.FilterBox = styled(Box)`
  border: 1px solid #eaeaea;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 0.75rem;
  ${SCREEN_SIZE.From.Tablet} {
    max-width: ${props => (props.isNewVoucherEnable ? '100%' : '328px')};
  }
`
SC.StyledFilter = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  cursor: pointer;
`
SC.FilterText = styled(Typography)`
  color: #696969;
  ${props => props.mobile && ' padding-bottom:1rem;'}
`
SC.FilterType = styled(Typography)`
  color: #285ab8;
  cursor: pointer;
  padding: 0.75rem 0.5rem;
  font-weight: ${props => props.active && ' bold'};
  svg {
    font-weight: bold;
    vertical-align: text-bottom;
  }
  ${SCREEN_SIZE.From.Tablet} {
    padding: 0.5rem;
  }
`
SC.StyledSvgArrowRight = styled(SvgArrowRight)`
  &.arrow-next {
    width: 1.5rem;
    height: 1.5rem;
  }
  ${SCREEN_SIZE.From.Tablet} {
    &.arrow-next {
      width: 1rem;
      height: 1rem;
    }
  }
`
SC.FilterPopup = styled(Box)`
  background: white;
  width: 100%;
  bottom: 0;
  position: absolute;
  left: 0;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 1.5rem;
`
SC.FilterChip = styled(ButtonOutlined)`
  cursor: pointer;
  border-radius: 3.125rem;
  border: 0.063rem solid #1454b8;
  color: inherit !important;
  ${props =>
    props.active &&
    'background: #E8F0FD !important; border: 0.063rem solid #1454B8 !important; color: #1454B8 !important'}
`

SC.ArrowDown = styled(SvgArrowDown)`
  margin: 0.25rem 0 0 0.5rem;
`

SC.FilterChipSVG = styled(Image)`
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  ${props => props.active && 'fill: #1454B8 !important'}
`

SC.NewFilterContainer = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: 0rem;
  display: flex;
  padding: 1px;
  ::-webkit-scrollbar {
    display: none;
  }
  .checkout-point-chip {
    div {
      order: 2;
      svg {
        margin: 0.25rem 0 0 0.25rem;
      }
    }
  }
`

SC.AccountLoader = styled.div`
  display: flex;
  margin-bottom: 1rem;
  width: 100%;
  flex-direction: column;
  div[role='circle'] {
    margin-right: 0.5rem;
    border-radius: 1.5625rem;
  }
`

SC.FilterLoader = styled.div`
  display: flex;
  margin-bottom: 1rem;
  span[role='circle'] {
    margin-right: 0.5rem;
    border-radius: 1.5625rem;
  }
`

SC.VoucherLoader = styled.div`
  display: flex;
  flex-wrap: wrap;
  div[role='square'] {
    border-radius: 0.25rem;
  }
  span:nth-child(1)[role='square'] {
    width: 30%;
  }
  span:nth-child(2)[role='square'] {
    width: 40%;
  }
  span:nth-child(3)[role='square'] {
    width: 95%;
  }
  span:nth-child(4)[role='square'] {
    width: 55%;
  }
  .ContainerLoader {
    width: 50%;
  }
`

SC.ResponsiveTab = styled(Tab)`
  ${SCREEN_SIZE.From.Tablet} {
    max-width: 11.25rem; // 180px
  }
  ${SCREEN_SIZE.Below.Tablet} {
    width: 50%;
  }
`

export default {
  ...SC,
  Grid,
  Image,
  TabPanel,
  Box,
  Tab,
  Tabs,
  TabList,
  TabContentTitle,
  Typography,
  SvgArrowRight,
  SvgArrowDown,
  SvgArrowUp,
  SvgCheck,
  ErrorIcon,
  ContentSection,
  PaddingBox,
  Skeleton,
}
