import React, { useEffect, useContext, useState } from 'react'
import getConfig from 'next/config'
import { default as StyledLink } from '@ntuctech/devex-tangram/Link'
import Loader from '../../Loader/Loader'
import SC from './elements'
import Grid from '@ntuctech/devex-tangram/Grid'
import styled from 'styled-components'
import { GTM_ENV_VAR as GEV } from '../../../lib/eventTrackerFunction'
import { get } from '../../../lib/fetch'
import VirtualCarousel from '../../../components/VirtualCarousel'
import { getConfigFor, getExpConfig } from '../../../utils/configService'
import { logError } from '../../../utils/logUtils'

import { SPLIT_FEATURES } from '../../../utils/constants'
import { Context } from '../../CheckoutAddressProvider'
import RecommendedProduct from '../../RecommendedProduct/RecommendedProduct'
import { SCREEN_SIZE } from '../../../lib/Media'

const {
  publicRuntimeConfig: { API_URL },
} = getConfig()

const Container = styled(SC.StyledSection)`
  ${props =>
    props.wallet
      ? `
    position: relative;
    ::before {
      content: "";
      position: absolute;
      top: 0;
      left: 2.5rem;
      width: calc(100% - 5rem);
      border-top: 1px solid #eaeaea;
    }
  `
      : `border-top: 1px solid #eaeaea;`}

  padding: 1.5rem ${props => (props.wallet ? '2.5rem' : '0')};
  font-family: Lato, sans-serif;
  ${props =>
    props.isVoucher &&
    'font-size: 0.875rem; line-height: 1.25rem; color: #1557BF;'}
  h6 {
    color: #333333;
    font-size: 1.125rem;
    display: flex;
    justify-content: space-between;
    line-height: 1.75rem;
    padding-bottom: 0.75rem;
  }
  .see-all {
    float: right;
    color: #1454b8;
    cursor: pointer;
    transform: translate(0, -1rem);
  }
  .see-all-button {
    float: right;
    background: #1454b8;
    border-radius: 50%;
  }
  .add-to-cart {
    height: 1.25rem;
    width: 1.25rem;
    min-height: 1.25rem !important;
    min-width: 1.25rem !important;
    margin: 0 auto;
  }
  .see-all-eligible-items {
    color: #1454b8;
    font-size: 0.875rem;
    font-family: Lato, sans-serif;
    display: inline-block;
    text-decoration: none;
  }
`

const Item = styled(Grid)`
  margin-top: 1rem;
  font-family: Lato, sans-serif;
`
const ItemName = styled(SC.Typography).attrs({ variant: 'body4' })`
  margin-top: 1.25rem;
  word-break: break-all;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  font-family: Lato, sans-serif;
  color: #333333;
`
const DisplayUnit = styled(SC.Typography).attrs({ variant: 'body4' })`
  font-size: 0.75rem;
  line-height: 1rem;
  font-family: Lato, sans-serif;
  color: #666666;
  font-weight: 400;
`
const StyledA = styled.a`
  cursor: pointer;
  text-decoration: none;
  display: block;
`

const HorizontalProductCollection = styled.div`
  white-space: normal;
  vertical-align: top;
  height: 99%; /* if it is 100%, box-shadow effect will not show at the bottom */
`

const VoucherEligibleItems = ({
  voucherDetails,
  track,
  isVoucher,
  isSideSheet,
  wallet,
}) => {
  const { checkoutAddress } = useContext(Context)
  const { eligibleItems = [], eligibleItemsLinks } = voucherDetails
  const [eligibleItemDetails, setEligibleItemDetails] = useState([])
  const [loading, setLoading] = useState(false)
  const [voucherMetaData, setVoucherMetaData] = useState(null)
  const { isEnabled: addToCartFlagEnabled } = getConfigFor(
    SPLIT_FEATURES.FE_ELIGIBLE_ITEM_ADD_TO_CART_ENABLED
  )

  useEffect(() => {
    const { tags } = voucherDetails

    async function getEligibleProductDetails(productSKUs) {
      setLoading(true)
      try {
        const response = await get(
          `${API_URL}/product/v2?storeId=${
            checkoutAddress.storeId
          }&sku=${encodeURIComponent(productSKUs)}`
        )
        if (response?.data.product?.length > 0) {
          setVoucherMetaData(response.data?.metaData)
          setEligibleItemDetails(response.data.product)
        }
      } catch (e) {
        logError(`Failed to fetch eligible items`, e)
      } finally {
        setLoading(false)
      }
    }

    if (
      voucherDetails.eligibleItems?.length > 0 &&
      addToCartFlagEnabled &&
      tags.includes('Online')
    ) {
      const productSKUs = voucherDetails.eligibleItems
        .map(item => item.sku)
        .join(',')
      getEligibleProductDetails(productSKUs)
    }
  }, [addToCartFlagEnabled, voucherDetails, checkoutAddress])

  // Check if feature is enabled
  const isEnabled =
    getExpConfig(SPLIT_FEATURES.PRICING_VOUCHER_PRODUCT_DETAILS)?.treatment ===
    'on'

  const handleTrack = () => {
    track({
      [GEV.AMPLITUDE_EVENT_NAME]: 'see eligible items clicked',
      [GEV.AMPLITUDE_VERTICAL]: 'rewards',
      [GEV.AMPLITUDE_CALL_TO_ACTION]: 'rewards | voucher details ',
      [GEV.AMPLITUDE_CALL_TO_ACTION_SUB_LOCATION]: `widget | ${voucherDetails.type}`,
      [GEV.AMPLITUDE_SAP_PRODUCT_ID]: eligibleItems.map(
        eligibleItem => eligibleItem.sku
      ),
      [GEV.AMPLITUDE_DBP_PRODUCT_ID]: eligibleItems.map(
        eligibleItem => eligibleItem.slug
      ),
      [GEV.AMPLITUDE_VOUCHER_CODE]: voucherDetails.code,
      [GEV.AMPLITUDE_VOUCHER_TYPE]: voucherDetails.type,
    })
  }

  // waiting for product/v2 API call to finish
  if (loading) {
    return <Loader />
  }

  const flagOnToDisplayNewItems =
    addToCartFlagEnabled && eligibleItemDetails?.length > 0
  const flagOffToDisplayOldItems =
    !addToCartFlagEnabled && eligibleItems?.length > 0
  const flagOnAndEligibleItemsPresent =
    eligibleItems?.length > 0 && eligibleItemDetails?.length === 0

  // Render eligible items component only
  // When eligible items is present
  const isVisible =
    isEnabled &&
    (flagOffToDisplayOldItems ||
      flagOnToDisplayNewItems ||
      flagOnAndEligibleItemsPresent)
  if (!isVisible) {
    return <></>
  }

  const onScrollCallback = () => {
    /* istanbul ignore next  */
    track({
      [GEV.EVENT_LABEL]:
        'Loc=voucher-wallet_promocode_detail_add_to_cart_swipe',
      [GEV.EVENT_ACTION]: 'auto-scroll',
      [GEV.EVENT_CAT]: 'Voucher_Wallet',
      [GEV.SCREEN_NAME]: 'Voucher Wallet',
      [GEV.PAGE_TYPE]: 'Voucher Wallet',
      [GEV.EVENT]: 'hm_push_event',
    })
  }

  const productProps = {
    pageType: 'voucher',
    onSave: val => val,
    isLoggedIn: true,
    asPath: window.location.pathname,
    metaData: voucherMetaData,
  }

  return (
    <Container
      data-testid="voucher-eligible-items"
      isSideSheet={isSideSheet}
      wallet={wallet}
      isVoucher={isVoucher}
    >
      <SC.Typography variant="titleS">Eligible items</SC.Typography>
      {eligibleItemsLinks?.web && (
        <SC.Box style={{ transform: 'translate(0px, -1rem)', float: 'right' }}>
          <StyledLink
            onClick={() => {
              handleTrack()
            }}
            size="m"
            href={eligibleItemsLinks?.web}
            color="#1454B8"
            weight="bold"
            data-testid="voucher-eligible-item-see-all-link"
            className="see-all-eligible-items"
          >
            See all
          </StyledLink>
        </SC.Box>
      )}
      <SC.Typography variant="body3" style={{ marginBottom: '1rem' }}>
        Buy these to use this voucher for delivery.
      </SC.Typography>
      {(!addToCartFlagEnabled || flagOnAndEligibleItemsPresent) && (
        <Grid container compact>
          {eligibleItems.map(item => (
            <Item
              item
              xs={4}
              key={item.sku}
              data-testid="voucher-eligible-item-list-item"
              onClick={() => {
                handleTrack()
              }}
            >
              <StyledA>
                <SC.Image reservedHeight="auto" src={item.image} />
                <ItemName>{item.name}</ItemName>
                <DisplayUnit>{item.displayUnit}</DisplayUnit>
              </StyledA>
            </Item>
          ))}
        </Grid>
      )}
      {/* display horizontal layout if eligibleItemDetails === 1 */}
      {addToCartFlagEnabled &&
        voucherDetails?.tags.includes('Online') &&
        eligibleItemDetails &&
        eligibleItemDetails.length === 1 && (
          <RecommendedProduct
            {...productProps}
            details={eligibleItemDetails[0]}
            layoutTypeVoucher="horizontal"
            isVoucherDetails
          />
        )}

      {/* display vertical layout in swimlane if eligibleItemDetails > 1 */}
      {addToCartFlagEnabled &&
        voucherDetails?.tags.includes('Online') &&
        eligibleItemDetails &&
        eligibleItemDetails.length > 1 && (
          <VirtualCarousel
            onScrollCallback={onScrollCallback}
            items={eligibleItemDetails}
            staticHeight={{
              [SCREEN_SIZE.From.MobileL]: 418,
              [SCREEN_SIZE.Below.MobileL]: 378,
            }}
            staticWidth={{
              [SCREEN_SIZE.From.MobileL]: 168,
              [SCREEN_SIZE.Below.MobileL]: 168,
            }}
            withBanner={false}
            scrollPercent={0.934}
          >
            {({ item, index }) => (
              <HorizontalProductCollection role="cell">
                <RecommendedProduct
                  {...productProps}
                  details={item}
                  productPosition={index + 1}
                  layoutTypeVoucher="swimlane"
                  isVoucherDetails
                />
              </HorizontalProductCollection>
            )}
          </VirtualCarousel>
        )}
    </Container>
  )
}

export default VoucherEligibleItems
